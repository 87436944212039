import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment, useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import NoResults from '../../../../shared/icons/NoResults';
import { HeaderContext } from '../../contexts/HeaderContext';
import EmptyState from '../EmptyState';
import List from '../List';
import ServiceHeader from '../ServiceHeader';
import ToggleText from '../ToggleText';
import Typography from '../Typography';
import MobileCard from './Card';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.neutral[200],
    minHeight: '100%',
  },
  withBorder: {
    borderTop: `1px solid ${theme.palette.neutral[200]}`,
  },
  header: {
    background: theme.palette.white,
    padding: '1.25rem',
  },
  title: {
    marginTop: '0.25rem',
  },
  emptyPageRoot: {
    display: 'flex',
    background: theme.palette.neutral[200],
    height: '100%',
  },
  emptyPage: {
    background: theme.palette.white,
    display: 'flex',
    padding: 0,
    marginTop: '0.25rem',
    height: '100%',
    width: '100%',
    textAlign: 'center',
  },
}));

const MultiPageServiceLayout = ({
  categories,
  expanded,
  firstCategory,
  selectService,
  services,
  setPageHeader,
  toggleDescription,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const [, setHeader] = useContext(HeaderContext);
  const [category, setCategory] = useState(null);
  const selectedCategory =
    category === null && categories.length > 0 ? firstCategory : category;

  const selectCategory = ({
    currentTarget: {
      dataset: { name },
    },
  }) => {
    setCategory(categories.find((category) => category.name === name));
  };

  useEffect(() => {
    setPageHeader(category, setCategory);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [category, setHeader]);

  if (!services.length) {
    return (
      <section className={classes.emptyPageRoot} data-testid="mobile-service">
        <div className={classes.emptyPage} data-testid="empty-state">
          <EmptyState
            icon={<NoResults />}
            title={
              <FormattedMessage
                id="Service.no_services"
                values={{ vendor: document.title }}
              />
            }
          />
        </div>
      </section>
    );
  }

  return (
    <section
      className={classNames(
        classes.root,
        (selectedCategory === null || category) && classes.withBorder,
      )}
      data-testid="mobile-service"
    >
      {category ? (
        <header aria-atomic="false" className={classes.header} role="alert">
          <Typography
            classes={{ root: classes.title }}
            component="p"
            variant="caption1"
          >
            <FormattedMessage
              id="Service.service_count"
              values={{ number: category.serviceCount }}
            />
          </Typography>
          <Typography
            classes={{ root: classes.title }}
            component="h1"
            variant="h5"
          >
            {category.name}
          </Typography>
        </header>
      ) : (
        <header aria-atomic="false" className={classes.header} role="alert">
          <Typography
            classes={{ root: classes.title }}
            component="h1"
            variant="h5"
          >
            <FormattedMessage id="Service.mobile_header" />
          </Typography>
        </header>
      )}
      <List>
        {selectedCategory === null || category ? (
          <>
            {services
              .filter((service) =>
                selectedCategory
                  ? service.categories.includes(selectedCategory.name)
                  : true,
              )
              .map((item) => (
                <MobileCard
                  action={selectService}
                  header={
                    <ServiceHeader
                      duration={item.duration}
                      group={item.group}
                      maximum={item.maxDuration}
                      minimum={item.minDuration}
                    />
                  }
                  icon
                  id={item.id}
                  key={item.id}
                  primary={item.name}
                  secondary={
                    item.description !== null ? (
                      <ToggleText
                        model={item}
                        setVariant={() => toggleDescription(item.id)}
                        variant={expanded}
                      />
                    ) : null
                  }
                  vendorId={item.vendorId}
                />
              ))}
          </>
        ) : (
          <>
            {categories.map((item) => (
              <MobileCard
                action={selectCategory}
                icon
                id={item.id}
                key={item.id}
                primary={item.name}
                secondary={
                  <FormattedMessage
                    id="Service.service_count"
                    values={{ number: item.serviceCount }}
                  />
                }
              />
            ))}
          </>
        )}
      </List>
    </section>
  );
};

MultiPageServiceLayout.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  firstCategory: PropTypes.shape({ name: PropTypes.string }),
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ).isRequired,
    }),
  ),
  setPageHeader: PropTypes.func.isRequired,
  toggleDescription: PropTypes.func,
};

MultiPageServiceLayout.defaultProps = {
  categories: [],
  loading: true,
  services: [],
};

export default MultiPageServiceLayout;
