import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import ForwardChevron from '../../../../shared/icons/ForwardChevron';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  default: {
    border: 'none',
  },
  error: {
    border: `1px solid ${theme.palette.danger[400]}`,
  },
  icon: {
    alignSelf: 'center',
    marginLeft: '3rem',
    '& svg': {
      fill: theme.palette.neutral[300],
    },
  },
  itemText: {
    alignItems: 'flex-start',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
  },
  listButton: {
    alignItems: 'center',
    appearance: 'none',
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.default,
    cursor: 'pointer',
    margin: 0,
    marginTop: '0.25rem',
    padding: 0,
    textAlign: 'left',
    width: '100%',
    '&:focus': {
      outline: `1px solid ${theme.palette.secondary[400]}`,
      boxShadow: theme.shadows.card,
    },
    '&:hover': {
      outline: `1px solid ${theme.palette.secondary[400]}`,
      boxShadow: theme.shadows.hover,
    },
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.8rem 1.25rem',
  },
  buttonContentNoSecondary: {
    alignItems: 'flex-end',
  },
  buttonHeader: {
    display: 'flex',
    width: '100%',
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    padding: '0.4rem 1.25rem 0.4rem 1rem',
  },
  primary: {
    marginBottom: '0.25rem',
  },
  secondaryText: {
    width: '100%',
  },
}));

const Card = ({
  action,
  adornment,
  ariaLabel,
  header,
  icon,
  id,
  primary,
  secondary,
  variant,
  vendorId,
  ...other
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  return (
    <li
      {...other}
      aria-label={
        ariaLabel || intl.formatMessage({ id: 'Navigation.aria_label.card' })
      }
    >
      <div className={classes.content}>
        <div
          className={classNames(classes.listButton, classes[variant])}
          data-id={id}
          data-name={primary}
          data-vendor={vendorId}
          onClick={action}
          onKeyPress={action}
          role="button"
          tabIndex="0"
        >
          {header ? <div className={classes.buttonHeader}>{header}</div> : null}
          <div
            className={classNames(
              classes.buttonContent,
              !secondary && classes.buttonContentNoSecondary,
            )}
          >
            <div className={classes.itemText}>
              <Typography classes={{ root: classes.primary }} variant="title">
                {primary}
              </Typography>
              {secondary ? (
                <Typography
                  classes={{ root: classes.secondaryText }}
                  variant="caption1"
                >
                  {secondary}
                </Typography>
              ) : null}
            </div>
            {icon ? (
              <div className={classes.icon}>
                <ForwardChevron ariaHidden />
              </div>
            ) : null}
            {adornment ? adornment : null}
          </div>
        </div>
      </div>
    </li>
  );
};

Card.propTypes = {
  action: PropTypes.func,
  adornment: PropTypes.element,
  ariaLabel: PropTypes.string,
  header: PropTypes.element,
  icon: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  secondary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  variant: PropTypes.oneOf(['default', 'error']),
  vendorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
};

Card.defaultProps = {
  action: () => {},
  adornment: null,
  ariaLabel: null,
  header: null,
  icon: false,
  secondary: null,
  variant: 'default',
};

export default Card;
