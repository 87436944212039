import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles, useTheme } from 'react-jss';
import { TABLET } from '../contexts/ViewModeContext';

const useStyles = createUseStyles((theme) => ({
  root: {
    display: 'flex',
    height: 'calc(100% - 2.5rem)',
    margin: '1.25rem',
  },
  tablet: {
    justifyContent: 'center',
    marginRight: '1.25rem',
  },
  container: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    display: 'flex',
    flexDirection: 'column',
    maxWidth: '67.5rem',
    width: '100%',
  },
  flat: {
    background: 'transparent',
    boxShadow: 'none',
  },
  justified: {
    justifyContent: 'center',
    margin: '1.25rem',
  },
  narrowContainer: {
    maxWidth: '37.5rem',
  },
  row: {
    flexDirection: 'row',
  },
}));

const CenterWrap = ({
  children,
  custom,
  flat = false,
  justified,
  narrow,
  row,
  view,
  ...other
}) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <section
      className={classNames(
        classes.root,
        justified && classes.justified,
        view === TABLET && classes.tablet,
      )}
      {...other}
    >
      {justified}
      {custom ? (
        children
      ) : (
        <div
          className={classNames(
            classes.container,
            flat && classes.flat,
            narrow && classes.narrowContainer,
            row && classes.row,
          )}
        >
          {children}
        </div>
      )}
    </section>
  );
};

CenterWrap.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]),
  custom: PropTypes.bool,
  flat: PropTypes.bool,
  justified: PropTypes.bool,
  narrow: PropTypes.bool,
  row: PropTypes.bool,
  view: PropTypes.number.isRequired,
};

CenterWrap.defaultProps = {
  children: null,
  custom: false,
  flat: false,
  justified: true,
  narrow: false,
  row: false,
};

export default CenterWrap;
