import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import NoResults from '../../../../shared/icons/NoResults';
import { ServiceSearchProvider } from '../../contexts/ServiceSearchContext';
import { useSettings } from '../../contexts/SettingsContext';
import CenterWrap from '../CenterWrap';
import EmptyState from '../EmptyState';
import Typography from '../Typography';
import MultiPageServiceLayout from './MultiPageServiceLayout';
import SinglePageServiceLayout from './SinglePageServiceLayout';

const useStyles = createUseStyles((theme) => ({
  emptyPage: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    padding: '1.875rem 2.5rem',
  },
  emptyPageContent: {
    width: '100%',
    height: '100%',
    display: 'flex',
  },
  header: {
    background: theme.palette.white,
    borderBottom: `1px solid ${theme.palette.neutral[200]}`,
    display: 'flex',
    justifyContent: 'space-between',
    maxHeight: '4rem',
    padding: '1.125rem 0',
    width: '100%',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexGrow: 1,
  },
  title: {
    color: theme.palette.black,
    fontSize: theme.textSizes.xl,
    fontWeight: theme.fontWeights.medium,
    marginBottom: '1.875rem',
  },
  widthShift: {
    transform: 'translateY(-10%)',
  },
}));

const Service = ({
  categories,
  category,
  expanded,
  firstCategory,
  mode,
  selectCategory,
  selectService,
  services,
  toggleDescription,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const { serviceStepLayout } = useSettings();

  if (!services.length) {
    return (
      <CenterWrap data-testid="empty-state" view={mode}>
        <div className={classes.emptyPage}>
          <Typography className={classes.title} component="h2" variant="title">
            <FormattedMessage id="Service.select_title" />
          </Typography>
          <div className={classes.emptyPageContent}>
            <EmptyState
              classes={{ overrides: classes.widthShift }}
              icon={<NoResults />}
              title={<FormattedMessage id="Service.no_services" />}
            />
          </div>
        </div>
      </CenterWrap>
    );
  }

  const props = {
    categories,
    category,
    classes,
    expanded,
    firstCategory,
    selectCategory,
    selectService,
    services,
    toggleDescription,
  };

  return (
    <CenterWrap
      data-testid="desktop-service"
      narrow={!!serviceStepLayout}
      view={mode}
    >
      {serviceStepLayout ? (
        <ServiceSearchProvider services={services}>
          <SinglePageServiceLayout {...props} />
        </ServiceSearchProvider>
      ) : (
        <MultiPageServiceLayout {...props} />
      )}
    </CenterWrap>
  );
};

Service.propTypes = {
  category: PropTypes.shape({ id: PropTypes.number }),
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  firstCategory: PropTypes.shape({ id: PropTypes.number }).isRequired,
  mode: PropTypes.number.isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
  toggleDescription: PropTypes.func.isRequired,
};

Service.defaultProps = {
  categories: [],
  services: [],
};

export default Service;
