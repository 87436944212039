import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import ForwardChevron from '../../../shared/icons/ForwardChevron';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  button: {
    alignItems: 'center',
    backgroundColor: theme.palette.white,
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.default,
    cursor: 'pointer',
    margin: 0,
    marginTop: '0.75rem',
    padding: 0,
    width: '100%',
    '&:focus': {
      borderColor: theme.palette.secondary[400],
      boxShadow: theme.shadows.card,
      outline: 0,
    },
    '&:hover': {
      border: `1px solid ${theme.palette.secondary['400']}`,
      boxShadow: theme.shadows.hover,
    },
  },
  buttonContent: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0.8rem 1.25rem',
  },
  icon: {
    marginLeft: '5rem',
    transform: 'translateY(30%)',
    '& svg:first-child': {
      fill: theme.palette.neutral[300],
    },
  },
  mobileIcon: {
    marginLeft: '1rem',
    alignSelf: 'center',
    '& svg:first-child': {
      fill: theme.palette.neutral[300],
    },
  },
  itemText: {
    alignItems: 'center',
    display: 'flex',
    textAlign: 'left',
  },
  primary: {
    display: 'block',
    maxWidth: '31.25rem',
    marginBottom: '0.5rem',
  },
  textWrapper: {
    marginLeft: '1rem',
  },
}));

const SinglePageCard = ({
  action,
  adornment,
  details,
  icon,
  id,
  frontAdornment,
  isMobile,
  primary,
  primaryId,
  vendorId,
  ...other
}) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  return (
    <li
      aria-label={intl.formatMessage(
        { id: 'Navigation.aria_label.card' },
        { navItem: primary },
      )}
    >
      <div
        className={classes.button}
        data-id={id}
        data-vendor={vendorId}
        onClick={action}
        onKeyPress={action}
        role="button"
        tabIndex="0"
        {...other}
      >
        <div className={classes.buttonContent}>
          <div className={classes.itemText}>
            {frontAdornment ? frontAdornment : null}
            <div className={classNames(frontAdornment && classes.textWrapper)}>
              <Typography
                classes={{
                  root: classNames(classes.primary),
                }}
                {...(primaryId ? { id: primaryId } : {})}
                variant="title"
              >
                {primary}
              </Typography>
              <div>{details}</div>
            </div>
          </div>
          {icon ? (
            <div
              className={classNames(
                isMobile ? classes.mobileIcon : classes.icon,
              )}
            >
              <ForwardChevron ariaHidden />
            </div>
          ) : null}
          {adornment ? adornment : null}
        </div>
      </div>
    </li>
  );
};

SinglePageCard.propTypes = {
  action: PropTypes.func,
  adornment: PropTypes.element,
  frontAdornment: PropTypes.element,
  details: PropTypes.element.isRequired,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  icon: PropTypes.bool,
  isMobile: PropTypes.bool,
  primary: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
  primaryId: PropTypes.string,
  vendorId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    .isRequired,
};

SinglePageCard.defaultProps = {
  action: () => {},
  adornment: null,
  frontAdornment: null,
  icon: false,
  isMobile: false,
  primaryId: '',
};

export default SinglePageCard;
