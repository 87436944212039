import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Formatter from '../../../shared/helpers/Formatter';
import ClockOutline from './icons/ClockOutline';
import GroupService from './icons/GroupService';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  dot: {
    background: theme.palette.neutral[200],
    borderRadius: '50%',
    height: '0.3125rem',
    left: '-0.35rem',
    position: 'relative',
    top: '0.35rem',
    width: '0.3125rem',
  },
  icon: {
    alignItems: 'center',
    display: 'flex',
    marginRight: '1rem',
    '& svg:first-child': {
      fill: theme.palette.neutral[400],
      height: '1rem',
    },
  },
  text: {
    lineHeight: '1rem',
  },
}));

const ServiceHeader = ({ duration, group, maximum, minimum }) => {
  const classes = useStyles({ theme: useTheme() });
  const intl = useIntl();
  let time = Formatter.duration(duration, intl);

  if (minimum && maximum) {
    time = `${Formatter.duration(minimum, intl)} - ${Formatter.duration(
      maximum,
      intl,
    )}`;
  }

  return (
    <>
      <span className={classes.icon}>
        <ClockOutline />
        <Typography
          classes={{ root: classNames(classes.text) }}
          variant="caption1"
        >
          {time}
        </Typography>
      </span>
      {group ? (
        <>
          <span className={classes.dot} />
          <span className={classes.icon}>
            <GroupService />
            <Typography
              classes={{ root: classNames(classes.text) }}
              variant="caption1"
            >
              <FormattedMessage id="ServiceHeader.group" />
            </Typography>
          </span>
        </>
      ) : null}
    </>
  );
};

ServiceHeader.propTypes = {
  duration: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  group: PropTypes.bool,
  maximum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  minimum: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

ServiceHeader.defaultProps = {
  group: false,
  maximum: null,
  minimum: null,
};

export default ServiceHeader;
