import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const Checkmark = ({ altText, classes }) => {
  const intl = useIntl();

  return (
    <svg
      className={classes.root}
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
    >
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.checkmark' })}
      </title>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z" />
    </svg>
  );
};

Checkmark.propTypes = {
  classes: PropTypes.shape({
    root: PropTypes.string,
  }),
  altText: PropTypes.string,
};

Checkmark.defaultProps = {
  altText: '',
  classes: {
    root: '',
  },
};

export default Checkmark;
