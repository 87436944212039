import PropTypes from 'prop-types';
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { debounce } from 'throttle-debounce';
import { SERVICE_SEARCH_THRESHOLD } from '../constants';

const ServiceSearchContext = createContext({
  query: '',
  handleSearch: () => {},
  filteredServices: [],
});

const useServiceSearch = () => useContext(ServiceSearchContext);

const ServiceSearchProvider = ({ children, services }) => {
  const [query, setQuery] = useState('');

  const queryRef = useRef(null);
  const countRef = useRef(null);

  const updateQuery = debounce(300, (value) => {
    setQuery(value);
  });

  const handleSearch = ({ currentTarget: { value } }) => {
    updateQuery(value.toLowerCase());
  };

  const filteredServices = useMemo(() => {
    if (services.length < SERVICE_SEARCH_THRESHOLD || query.length < 2) {
      return services;
    }

    return services.filter(
      (service) =>
        service.name.toLowerCase().includes(query) ||
        service.description?.toLowerCase()?.includes(query),
    );
  }, [query, services]);

  useEffect(() => {
    queryRef.current = query;
    countRef.current = filteredServices.length;
  }, [query, filteredServices.length]);

  return (
    <ServiceSearchContext.Provider
      value={{
        filteredServices,
        handleSearch,
        query,
      }}
    >
      {children}
    </ServiceSearchContext.Provider>
  );
};

ServiceSearchProvider.propTypes = {
  children: PropTypes.element.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ).isRequired,
    }),
  ),
};

ServiceSearchProvider.defaultProps = {
  services: [],
};

export { ServiceSearchContext, ServiceSearchProvider, useServiceSearch };
