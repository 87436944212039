import Item from './Item';

/**
 * @param {Object} resource
 *
 * @returns {Object}
 */
const formatV3Service = (resource) => ({
  id: resource.id,
  name: resource.name,
  description: resource.description,
  instructions: resource.instructions,
  duration: resource.duration,
  group: Boolean(resource.group),
  maxDuration: resource.max_duration,
  meetingMethods: resource.meeting_methods,
  minDuration: resource.min_duration,
  requireLocation: resource.require_location_selection,
  categories: resource.categories.map((category) => category.name),
  visibility: resource.visibility,
  fastRouteMessage: resource.fast_route_message,
  fastRouteURL: resource.fast_route_url,
  timeToCancel: resource.time_to_cancel,
  timeToReschedule: resource.time_to_reschedule,
  vendorId: resource.vendor_id,
});

const formatServicesAndCategories = (services, primary) => {
  const counts = {};
  const items = services.map((service) => formatV3Service(service));
  const categories = services.reduce((accumulator, service) => {
    service.categories.forEach((category) => {
      if (!counts[category.name]) {
        counts[category.name] = 0;
      }

      counts[category.name] += 1;
      if (!accumulator[category.name]) {
        accumulator[category.name] = category;
      }
    });

    return accumulator;
  }, {});
  const separators = Object.values(categories)
    .map((separator) => {
      separator.serviceCount = counts[separator.name];

      return separator;
    })
    .sort((a, b) => {
      // Other category should always be at the end
      if (a.name === 'Other') {
        return 1;
      }
      if (b.name === 'Other') {
        return -1;
      }

      // any non-primary categories should be after the primary categories, but before "other"
      if (a.vendor_id === primary && b.vendor_id !== primary) {
        return -1;
      }

      if (a.sort_order < b.sort_order) {
        return -1;
      }

      if (a.sort_order > b.sort_order) {
        return 1;
      }

      return a.name > b.name ? 1 : -1;
    });

  return {
    categories: separators,
    firstCategory: Item.first(separators),
    services: items,
  };
};

export default { formatV3Service, formatServicesAndCategories };
