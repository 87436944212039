import 'core-js';
import { createBrowserHistory } from 'history';
import nested from 'jss-plugin-nested';
import React from 'react';
import { render } from 'react-dom';
import { IntlProvider } from 'react-intl';
import { jss, ThemeProvider } from 'react-jss';
import { Router } from 'react-router-dom';
import reset from 'reset-jss';
import App from './components/App';
import { DomainsProvider } from './contexts/DomainsContext';
import { HeaderProvider } from './contexts/HeaderContext';
import { SettingsProvider } from './contexts/SettingsContext';
import { ViewModeProvider } from './contexts/ViewModeContext';
import Theme from './helpers/Theme';
import messages from './locales';

const history = createBrowserHistory();

jss.use(nested());
jss.createStyleSheet(reset).attach();

const application = (
  <Router history={history}>
    <IntlProvider locale="en" messages={messages.en}>
      <ViewModeProvider>
        <ThemeProvider theme={Theme.create()}>
          <SettingsProvider>
            <HeaderProvider>
              <DomainsProvider>
                <App />
              </DomainsProvider>
            </HeaderProvider>
          </SettingsProvider>
        </ThemeProvider>
      </ViewModeProvider>
    </IntlProvider>
  </Router>
);

render(application, document.getElementById('app'));
