import React, { useReducer } from 'react';
import { createUseStyles } from 'react-jss';
import { MOBILE, TABLET, useViewMode } from '../contexts/ViewModeContext';

const useStyles = createUseStyles({
  notice: {
    background: '#007c89',
    padding: '8px 14px',
    color: 'white',
    'z-index': 1069,
    'margin-bottom': '-0.375rem',
  },
  noticeLink: {
    color: 'white',
  },
  closeButton: {
    'margin-left': '15px',
    'margin-bottom': '10px',
    color: 'white',
    float: 'right',
    'font-weight': 'bold',
    'font-size': '22px',
    'line-height': '20px',
    cursor: 'pointer',
    transition: '0.3s',
    '&:hover': {
      color: 'black',
    },
  },
});

const Notice = () => {
  const viewMode = useViewMode();
  const [hideNotice, setHideNotice] = useReducer(
    (state, newState) => newState,
    0,
  );

  const classes = useStyles();

  const noticeShouldDisplay = [MOBILE, TABLET].includes(viewMode);

  return !noticeShouldDisplay || hideNotice === 1 ? (
    ''
  ) : (
    <div className={classes.notice} role="alert">
      <span
        aria-label="Close"
        className={classes.closeButton}
        onClick={() => setHideNotice(1)}
        onKeyPress={() => setHideNotice(1)}
        role="button"
        tabIndex={0}
      >
        &times;
      </span>
      <b>Notice to Screen Reader Users.</b>
      {'\n'}
      This feature is temporarily incompatible with some screen readers. We
      intend to have the issue fixed soon. In the meantime, for accessible
      scheduling please call
      {'\n'}
      <a className={classes.noticeLink} href="tel:800-233-2328">
        800&#8209;233&#8209;2328
      </a>
      {'\n'}
      to schedule an appointment. We apologize for the inconvenience.
    </div>
  );
};

export default Notice;
