import classNames from 'classnames';
import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';

const descriptionHeight = 76; // Four lines of standard-sized text

const useStyles = createUseStyles((theme) => ({
  button: {
    color: theme.palette.secondary[400],
    cursor: 'pointer',
    display: 'block',
    fontSize: theme.textSizes.sm,
    lineHeight: 1,
    paddingRight: '1rem',
    paddingTop: '1rem',
    '& span': {
      borderBottom: `1px dotted ${theme.palette.secondary[400]}`,
    },
    '&:focus': {
      borderBottomColor: theme.palette.secondary[500],
      outline: 0,
      '& span': {
        color: theme.palette.secondary[500],
      },
    },
    '&:hover': {
      borderBottomColor: theme.palette.secondary[500],
      '& span': {
        color: theme.palette.secondary[500],
      },
    },
  },
  description: {
    color: theme.palette.neutral[400],
    fontSize: theme.textSizes.sm,
    textAlign: 'left',
    lineHeight: 1.35,
    '& p': {
      marginBottom: '0.25rem',
    },
    '& ol': {
      listStylePosition: 'outside',
      listStyleType: 'decimal',
      marginBottom: '0.25rem',
      marginLeft: '1.25rem',
      '& li': {
        marginBottom: '0.25rem',
      },
    },
    '& ul': {
      listStylePosition: 'outside',
      listStyleType: 'disc',
      marginBottom: '0.25rem',
      marginLeft: '1.25rem',
      '& li': {
        marginBottom: '0.25rem',
      },
    },
    '& a': {
      ...theme.components.anchors.default,
    },
    position: 'relative',
  },
  readMore: {
    height: 'auto',
  },
  readLess: {
    height: descriptionHeight,
    overflow: 'hidden',
    '&::before': {
      content: '""',
      width: '100%',
      height: '100%',
      position: 'absolute',
      display: 'block',
      left: 0,
      top: 0,
      background: `linear-gradient(rgba(255,255,255,0) 65%, ${theme.palette.white} 100%)`,
      fallbacks: [
        {
          background: `-webkit-linear-gradient(90deg, rgba(255,255,255,0) 65%, ${theme.palette.white} 100%)`,
        }, // Chrome 10+, Saf5.1+
        {
          background: `-moz-linear-gradient(90deg, rgba(255,255,255,0) 65%, ${theme.palette.white} 100%)`,
        }, // FF3.6+
      ],
    },
  },
}));

const ToggleText = ({ model, setVariant, variant }) => {
  const classes = useStyles({ theme: useTheme() });

  const [toggleable, setToggleable] = useState(false);
  const descriptionRef = useRef();

  useLayoutEffect(() => {
    const { current } = descriptionRef;

    setToggleable(current.offsetHeight > descriptionHeight);
  }, []);

  const setClicked = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setVariant();
  };

  return (
    <>
      <p
        className={classNames(
          classes.description,
          toggleable &&
            (variant === model.id ? classes.readMore : classes.readLess),
        )}
        dangerouslySetInnerHTML={{ __html: model.description }}
        data-testid={`service-description-${model.id}`}
        ref={descriptionRef}
      />
      {toggleable ? (
        <span
          className={classes.button}
          onClick={setClicked}
          onKeyPress={setClicked}
          role="button"
          tabIndex="0"
        >
          {variant === model.id ? (
            <FormattedMessage id="Service.read_less" />
          ) : (
            <FormattedMessage id="Service.read_more" />
          )}
        </span>
      ) : null}
    </>
  );
};

ToggleText.propTypes = {
  model: PropTypes.shape({
    description: PropTypes.string,
    id: PropTypes.number,
  }).isRequired,
  setVariant: PropTypes.func.isRequired,
  variant: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

export default ToggleText;
