import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import DesktopCard from './desktop/Card';
import List from './List';
import ServiceHeader from './ServiceHeader';
import ToggleText from './ToggleText';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    background: theme.palette.white,
    borderRadius: theme.borderRadius.default,
    boxShadow: theme.shadows.default,
    display: 'flex',
    height: 'calc(100% - 1.25rem)',
    marginBottom: '1.25rem',
    marginLeft: '2.5rem',
    marginRight: '2.5rem',
  },
  button: {
    alignItems: 'center',
    appearance: 'none',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    justifyContent: 'space-between',
    margin: 0,
    padding: 0,
    width: '100%',
  },
  icon: {
    marginLeft: '3rem',
    '& svg:first-child': {
      fill: theme.palette.neutral[300],
    },
  },
  info: {
    textAlign: 'left',
  },
  service: {
    border: `1px solid ${theme.palette.neutral[200]}`,
    borderRadius: theme.borderRadius.default,
    marginBottom: '1.25rem',
    padding: '0.8rem 1.25rem',
  },
  services: {
    flexGrow: 1,
    padding: '1.875rem 2.5rem',
    overflowY: 'auto',
  },
  title: {
    marginBottom: '1.875rem',
  },
}));

const ServiceList = ({
  expanded,
  selectService,
  selectedCategory,
  services,
  toggleDescription,
}) => {
  const classes = useStyles({ theme: useTheme() });

  return (
    <section className={classes.services}>
      <section className={classes.title}>
        <Typography component="h1" variant="h5">
          <FormattedMessage id="Service.select_title" />
        </Typography>
      </section>
      <List>
        {services
          .filter((service) =>
            selectedCategory
              ? service.categories.includes(selectedCategory.name)
              : true,
          )
          .map((item) => (
            <DesktopCard
              action={selectService}
              header={
                <ServiceHeader
                  duration={item.duration}
                  group={item.group}
                  maximum={item.maxDuration}
                  minimum={item.minDuration}
                />
              }
              icon
              id={item.id}
              key={item.id}
              primary={item.name}
              secondary={
                item.description !== null ? (
                  <ToggleText
                    model={item}
                    setVariant={() => toggleDescription(item.id)}
                    variant={expanded}
                  />
                ) : null
              }
              vendorId={item.vendorId}
            />
          ))}
      </List>
    </section>
  );
};

ServiceList.propTypes = {
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  selectService: PropTypes.func.isRequired,
  selectedCategory: PropTypes.shape({ name: PropTypes.string }).isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ).isRequired,
    }),
  ).isRequired,
  toggleDescription: PropTypes.func.isRequired,
};

export default ServiceList;
