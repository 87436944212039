import PropTypes from 'prop-types';
import React, { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { HeaderContext } from '../../contexts/HeaderContext';
import { ServiceSearchProvider } from '../../contexts/ServiceSearchContext';
import { SettingsContext } from '../../contexts/SettingsContext';
import MultiPageServiceLayout from './MultiPageServiceLayout';
import SinglePageServiceLayout from './SinglePageServiceLayout';

const Service = ({
  categories,
  expanded,
  firstCategory,
  selectService,
  services,
  toggleDescription,
}) => {
  const [, setHeader] = useContext(HeaderContext);
  const { serviceStepLayout } = useContext(SettingsContext);

  const setPageHeader = (category, setCategory) => {
    const back = () => {
      setCategory(null);
    };

    setHeader({
      action: category ? back : null,
      title: <FormattedMessage id="Steps.services" />,
    });
  };

  const props = {
    categories,
    expanded,
    firstCategory,
    selectService,
    services,
    setPageHeader,
    toggleDescription,
  };

  if (serviceStepLayout) {
    return (
      <ServiceSearchProvider services={services}>
        <SinglePageServiceLayout {...props} />
      </ServiceSearchProvider>
    );
  }
  return <MultiPageServiceLayout {...props} />;
};

Service.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  expanded: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  firstCategory: PropTypes.shape({ name: PropTypes.string }).isRequired,
  loading: PropTypes.bool,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ).isRequired,
    }),
  ),
  toggleDescription: PropTypes.func.isRequired,
};

Service.defaultProps = {
  categories: [],
  loading: true,
  services: [],
};

export default Service;
