import PropTypes from 'prop-types';
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles } from 'react-jss';
import { SERVICE_SEARCH_THRESHOLD } from '../../constants';
import { useServiceSearch } from '../../contexts/ServiceSearchContext';
import Alert from '../Alert';
import CategoryPicker from '../CategoryPicker';
import SearchableInput from '../forms/SearchableInput';
import List from '../List';
import SinglePageCategory from '../SinglePageCategory';
import Typography from '../Typography';

const useStyles = createUseStyles({
  serviceHeader: {
    padding: '2rem 2rem 1rem 2rem',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  search: {
    padding: '0.5rem 2rem',
  },
  alert: {
    padding: '1rem 2rem',
  },
});

const SinglePageServiceLayout = ({
  categories,
  category,
  classes: injectedClasses,
  selectCategory,
  selectService,
  services,
}) => {
  const classes = { ...useStyles(), ...injectedClasses };

  const { filteredServices, handleSearch, query } = useServiceSearch();

  const handleSelectService = (event) => {
    selectService(event);
  };

  return (
    <div data-testid="single-page-layout">
      <div>
        <section className={classes.serviceHeader}>
          <Typography component="h2" variant="h5">
            <FormattedMessage id="Service.select_title" />
          </Typography>
          {categories.length ? (
            <div className={classes.picker}>
              <CategoryPicker
                categories={categories}
                category={category}
                selectCategory={selectCategory}
              />
            </div>
          ) : null}
        </section>
        {services.length >= SERVICE_SEARCH_THRESHOLD ? (
          <SearchableInput className={classes.search} onChange={handleSearch} />
        ) : null}
        {filteredServices.length || !query ? (
          categories.length ? (
            <List>
              {category ? (
                <SinglePageCategory
                  category={category}
                  inListItem
                  selectService={handleSelectService}
                  services={filteredServices}
                />
              ) : (
                categories.map((categoryItem) => (
                  <SinglePageCategory
                    category={categoryItem}
                    inListItem
                    key={categoryItem.id}
                    selectService={handleSelectService}
                    services={filteredServices}
                  />
                ))
              )}
            </List>
          ) : (
            <SinglePageCategory
              category={null}
              selectService={handleSelectService}
              services={filteredServices}
            />
          )
        ) : (
          <div className={classes.alert}>
            <Alert
              message={<FormattedMessage id="Ui.search.no_results_found" />}
              variant="error"
            />
          </div>
        )}
      </div>
    </div>
  );
};

SinglePageServiceLayout.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  category: PropTypes.shape({ id: PropTypes.number }),
  classes: PropTypes.objectOf(PropTypes.string).isRequired,
  selectCategory: PropTypes.func.isRequired,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(PropTypes.string).isRequired,
    }),
  ),
};

SinglePageServiceLayout.defaultProps = {
  categories: [],
  category: null,
  services: [],
};

export default SinglePageServiceLayout;
