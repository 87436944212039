import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { createUseStyles } from 'react-jss';
import List from './List';
import ServiceDetails from './ServiceDetails';
import SinglePageCard from './SinglePageCard';
import Typography from './Typography';

const useStyles = createUseStyles({
  mobileServices: {
    flexGrow: 1,
    paddingTop: '1rem',
  },
  services: {
    flexGrow: 1,
    padding: '1rem 2rem',
  },
  title: {
    marginBottom: '0.5rem',
  },
});

const SinglePageCategory = ({
  category,
  inListItem,
  isMobile,
  selectService,
  services,
}) => {
  const classes = useStyles();
  const categoryServices = services.filter((service) =>
    category ? service.categories.includes(category.name) : true,
  );

  const content = categoryServices.length ? (
    <section
      className={classNames(
        isMobile ? classes.mobileServices : classes.services,
      )}
    >
      <div className={classes.title}>
        {category ? (
          <Typography
            classes={{ root: classes.name }}
            component="h3"
            variant="h6"
          >
            {category.name}
          </Typography>
        ) : null}
      </div>
      <List>
        {categoryServices.map((item) => (
          <SinglePageCard
            action={selectService}
            details={
              <ServiceDetails
                description={item.description}
                duration={item.duration}
                group={item.group}
                id={item.id}
                maximum={item.maxDuration}
                minimum={item.minDuration}
              />
            }
            icon
            id={item.id}
            isMobile={isMobile}
            key={item.id}
            primary={item.name}
            vendorId={item.vendorId}
          />
        ))}
      </List>
    </section>
  ) : null;

  return content && inListItem ? <li>{content}</li> : content;
};

SinglePageCategory.propTypes = {
  category: PropTypes.shape({ name: PropTypes.string }),
  inListItem: PropTypes.bool,
  isMobile: PropTypes.bool,
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ).isRequired,
    }),
  ),
};

SinglePageCategory.defaultProps = {
  category: null,
  inListItem: false,
  isMobile: false,
  services: [],
};

export default SinglePageCategory;
