import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';

const BackArrow = ({ altText }) => {
  const intl = useIntl();

  return (
    <svg height="24" width="24" xmlns="http://www.w3.org/2000/svg">
      <title>
        {altText || intl.formatMessage({ id: 'Svg.alt_text.back' })}
      </title>
      <path d="M0 0h24v24H0z" fill="none" />
      <path d="M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z" />
    </svg>
  );
};

BackArrow.propTypes = {
  altText: PropTypes.string,
};

BackArrow.defaultProps = {
  altText: '',
};

export default BackArrow;
