import PropTypes from 'prop-types';
import React, { useContext, useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import { SERVICE_SEARCH_THRESHOLD } from '../../constants';
import { HeaderContext } from '../../contexts/HeaderContext';
import { useServiceSearch } from '../../contexts/ServiceSearchContext';
import { isSmallScreen } from '../../contexts/ViewModeContext';
import Alert from '../Alert';
import CategoryPicker from '../CategoryPicker';
import SearchableInput from '../forms/SearchableInput';
import List from '../List';
import SinglePageCategory from '../SinglePageCategory';
import Typography from '../Typography';

const useStyles = createUseStyles((theme) => ({
  background: {
    background: theme.palette.white,
    padding: '1rem',
  },
  serviceHeader: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  picker: {
    position: 'absolute',
    right: '0rem',
  },
  smallScreenPicker: {
    position: 'relative',
  },
  search: {
    padding: '1.5rem 0 0.5rem',
  },
  smallScreenSearch: {
    padding: '0.5rem 0 0.5rem',
  },
  alert: {
    padding: '1rem 0',
  },
}));

const SinglePageServiceLayout = ({
  categories,
  selectService,
  services,
  setPageHeader,
}) => {
  const classes = useStyles({ theme: useTheme() });

  const [, setHeader] = useContext(HeaderContext);
  const [category, setCategory] = useState(null);

  const { filteredServices, handleSearch, query } = useServiceSearch();

  const handleSelectService = (event) => {
    selectService(event);
  };

  useEffect(() => {
    setPageHeader(category, setCategory);

    // In order to introduce linting to all JS projects without introducing
    // issues we are explicitly ignoring the react-hooks/exhaustive-deps.
    //
    // TODO: Clean up all instances of `eslint-disable-next-line react-hooks/exhaustive-deps`
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setHeader]);

  const selectCategory = (name) => {
    setCategory(categories.find((cat) => cat.name === name));
  };

  return (
    <div className={classes.background} data-testid="mobile-single-page-layout">
      <div aria-atomic="false" role="alert">
        <section className={classes.serviceHeader}>
          <Typography component="h2" variant="h5">
            <FormattedMessage id="Service.select_title" />
          </Typography>
          {categories.length > 0 ? (
            <div
              className={
                isSmallScreen() ? classes.smallScreenPicker : classes.picker
              }
            >
              <CategoryPicker
                categories={categories}
                category={category}
                selectCategory={selectCategory}
              />
            </div>
          ) : null}
        </section>
        {services.length >= SERVICE_SEARCH_THRESHOLD ? (
          <SearchableInput
            className={
              isSmallScreen() ? classes.smallScreenSearch : classes.search
            }
            onChange={handleSearch}
          />
        ) : null}
        {filteredServices.length || !query ? (
          categories.length > 0 ? (
            <List>
              {category ? (
                <SinglePageCategory
                  category={category}
                  inListItem
                  isMobile
                  selectService={handleSelectService}
                  services={filteredServices}
                />
              ) : (
                categories.map((categoryItem) => (
                  <SinglePageCategory
                    category={categoryItem}
                    inListItem
                    isMobile
                    key={categoryItem.id}
                    selectService={handleSelectService}
                    services={filteredServices}
                  />
                ))
              )}
            </List>
          ) : (
            <SinglePageCategory
              category={null}
              isMobile
              selectService={handleSelectService}
              services={filteredServices}
            />
          )
        ) : (
          <div className={classes.alert}>
            <Alert
              message={<FormattedMessage id="Ui.search.no_results_found" />}
              variant="error"
            />
          </div>
        )}
      </div>
    </div>
  );
};

SinglePageServiceLayout.propTypes = {
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
    }),
  ),
  selectService: PropTypes.func.isRequired,
  services: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      name: PropTypes.string,
      description: PropTypes.string,
      categories: PropTypes.arrayOf(
        PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      ).isRequired,
    }),
  ),
  setPageHeader: PropTypes.func.isRequired,
};

SinglePageServiceLayout.defaultProps = {
  categories: [],
  services: [],
};

export default SinglePageServiceLayout;
