import PropTypes from 'prop-types';
import React, { createContext, useContext, useReducer } from 'react';

const SettingsContext = createContext({});

const useSettings = () => useContext(SettingsContext);

const SettingsProvider = ({ children }) => {
  const [settings] = useReducer(
    (_state, newState) => newState,
    window.state.settings,
  );

  return (
    <SettingsContext.Provider value={settings}>
      {children}
    </SettingsContext.Provider>
  );
};

SettingsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { SettingsContext, SettingsProvider, useSettings };
