import { Listbox } from '@headlessui/react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Checkmark from './icons/Checkmark';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  button: {
    alignItems: 'center',
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    margin: 0,
    padding: '0.4rem 0.7rem',
    textAlign: 'left',
    width: '100%',
    '&:hover': {
      background: theme.palette.neutral[200],
    },
  },
  active: {
    background: theme.palette.neutral[200],
  },
  checkmark: {
    minWidth: '1.5rem',
  },
  hidden: {
    opacity: 0,
  },
  root: {
    textAlign: 'left',
    '& svg': {
      fill: theme.palette.neutral[400],
      height: '1rem',
    },
  },
  title: {
    cursor: 'pointer',
    padding: '0.5rem',
  },
}));

const SelectableListItem = ({ checked, value, title }) => {
  const intl = useIntl();
  const classes = useStyles({ theme: useTheme() });

  return (
    <Listbox.Option as="div" key={value} value={value}>
      {({ active }) => (
        <div
          className={classNames(
            classes.button,
            active ? classes.active : '',
            classes.root,
          )}
        >
          <Checkmark
            altText={
              checked
                ? intl.formatMessage({ id: 'Svg.alt_text.item_selected' })
                : null
            }
            classes={{
              root: classNames(
                classes.checkmark,
                checked ? '' : classes.hidden,
              ),
            }}
          />
          <Typography classes={{ root: classes.title }} variant="subtitle">
            {title}
          </Typography>
        </div>
      )}
    </Listbox.Option>
  );
};

SelectableListItem.propTypes = {
  checked: PropTypes.bool,
  value: PropTypes.string,
  title: PropTypes.element.isRequired,
};

SelectableListItem.defaultProps = {
  checked: false,
  value: null,
};

export default SelectableListItem;
