import PropTypes from 'prop-types';
import React, { createContext, useContext, useState } from 'react';

const DomainsContext = createContext({
  getVendorDomain: () => {},
  primary: null,
});

const useDomains = () => useContext(DomainsContext);

const DomainsProvider = ({ children }) => {
  const [domains] = useState(window.state.domains);
  const [primary] = useState(window.state.primary);

  const getVendorDomain = (vendorId) => {
    const vendor = domains.find((vendor) => vendor.id === vendorId);

    return vendor.url;
  };

  return (
    <DomainsContext.Provider value={{ getVendorDomain, primary }}>
      {children}
    </DomainsContext.Provider>
  );
};

DomainsProvider.propTypes = {
  children: PropTypes.element.isRequired,
};

export { DomainsContext, DomainsProvider, useDomains };
