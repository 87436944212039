import classNames from 'classnames';
import React from 'react';
import { useIntl } from 'react-intl';
import { createUseStyles, useTheme } from 'react-jss';
import Detective from '../../../shared/helpers/Detective';
import mode from '../../../shared/helpers/Mode';
import { HEADER_HEIGHT } from '../constants';
import { useHeader } from '../contexts/HeaderContext';
import {
  useViewMode,
  MOBILE,
  isSmallScreen,
  DESKTOP,
} from '../contexts/ViewModeContext';
import BackArrow from './icons/BackArrow';
import Typography from './Typography';

const useStyles = createUseStyles((theme) => ({
  root: {
    alignItems: 'center',
    background: theme.palette.white,
    borderTop: `0.375rem solid ${theme.palette.primary[400]}`,
    boxShadow: theme.shadows.default,
    display: 'flex',
    flexShrink: 0,
    height: HEADER_HEIGHT.DESKTOP,
    justifyContent: 'center',
    textAlign: 'center',
    position: Detective.notInternetExplorer11() ? 'sticky' : 'relative',
    top: 0,
    zIndex: 950,
  },
  action: {
    appearance: 'none',
    background: 'transparent',
    border: 'none',
    cursor: 'pointer',
    margin: 0,
    padding: 0,
    '&:first-child': {
      marginLeft: '1rem',
      marginRight: 'auto',
    },
    '&:last-child': {
      marginLeft: 'auto',
      marginRight: '1rem',
      opacity: 0,
      pointerEvents: 'none',
      visibility: 'hidden',
    },
    '& svg': {
      fill: theme.palette.neutral[400],
    },
    '&:focus': {
      '& svg': {
        fill: theme.palette.black,
      },
    },
  },
  container: {
    alignItems: 'center',
    display: 'flex',
    width: '100%',
  },
  customTitle: {
    height: 'auto',
    maxHeight: '2.25rem',
    maxWidth: '70%',
  },
  desktopLogo: {
    padding: '0.25rem 0.5rem',
  },
  logo: {
    maxWidth: '100%',
    maxHeight: '3.125rem',
  },
  logoWrapper: {
    fontSize: '1.25rem',
    fontWeight: '500',
    lineHeight: '1.625rem',
  },
  stepTitle: {
    width: '100%',
    flex: 1,
    minWidth: '140px',
    textAlign: 'center',
    paddingRight: '2.5rem',
  },
  vendorName: {
    width: '100%',
    textAlign: 'center',
  },
}));

const Header = () => {
  const classes = useStyles({
    theme: useTheme(),
    isSmallScreen: isSmallScreen(),
  });
  const intl = useIntl();
  const viewMode = useViewMode();
  const [{ action, title }] = useHeader();

  if (viewMode === DESKTOP || mode.isEmbedded()) {
    return null;
  }

  const { logo } = window.state.theme;

  const header =
    logo !== null ? (
      <div className={classes.logoWrapper}>
        <h1>
          <img
            alt={document.title}
            className={classNames(classes.desktopLogo, classes.logo)}
            data-testid="logo"
            src={logo}
          />
        </h1>
      </div>
    ) : (
      <Typography
        classes={{ root: classNames(classes.customTitle, classes.vendorName) }}
        component="h1"
        variant="h6"
      >
        {title}
      </Typography>
    );

  const iFrameHeader = (
    <Typography
      classes={{ root: classes.customTitle }}
      component="h1"
      variant="h6"
    >
      {title}
    </Typography>
  );

  return (
    <header className={classes.root}>
      {action && viewMode === MOBILE ? (
        <div className={classes.container}>
          <button
            aria-label={intl.formatMessage({ id: 'Ui.back' })}
            className={classes.action}
            data-testid="back"
            onClick={action}
            title={intl.formatMessage({ id: 'Ui.back' })}
            type="button"
          >
            <BackArrow />
          </button>
          <Typography
            classes={{ root: classes.stepTitle }}
            component="h1"
            variant="h6"
          >
            {title}
          </Typography>
        </div>
      ) : Detective.inIFrame() ? (
        iFrameHeader
      ) : (
        header
      )}
    </header>
  );
};

export default Header;
